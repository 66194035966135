
.back {
  left: 50%;
  a {
    color: #fff !important;
  }
}

@media only screen and (max-width: 769px) {
  .back {
    left: 0;
  }
}
.section {
  width: 50%;
  margin: 0 auto;
  flex-direction: column;
  -webkit-transition: width 0.5s;
  transition: width 0.5s;
  padding: 0 40px !important;
  form {
    width: 100%;
  }
}
@media screen and (max-width: 1300px) {
  .section {
    width: 70%;
  }
}
@media screen and (max-width: 1100px) {
  .section {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .section {
    width: 70%;
  }
}
@media screen and (max-width: 600px) {
  .section {
    width: 100%;
  }
}
